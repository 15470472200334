import React, { PropsWithChildren, useEffect, useRef, useState } from "react"
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { Icon } from "../../icon/icon"
import {
  GlobalButtonWrapper,
  QEWrapper,
  StyledCloseButton,
  StyledGlobalButton,
  StyledPopUp,
} from "./StyledQuickExit"

export const QuickExitFragment: React.FC<PropsWithChildren> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false)
  const [isHoveringPopup, setIsHoveringPopup] = useState<boolean>(false)
  const hidePopupTimerRef = useRef<NodeJS.Timeout | null>(null)
  const [isPopupRendered, setPopupRendered] = useState<boolean>(false)

  useEffect(() => {
    setPopupVisible(true)
    setTimeout(() => setPopupVisible(true), 50)

    const hidePopupTimer = setTimeout(() => {
      setPopupVisible(false)
      setTimeout(() => setPopupRendered(false), 300)
    }, 10000)

    return () => clearTimeout(hidePopupTimer);
  }, [])

  const handleQuickExit = () => {
    window.open("http://www.bom.gov.au/", "_blank", "noopener, noreferrer")
    window.history.replaceState(null, "", "/")
    window.location.replace("https://google.com.au/")
  }

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem("qe_visibility", "true")
  };

  const handleMouseEnter = () => {
    if (hidePopupTimerRef.current) {
      clearTimeout(hidePopupTimerRef.current)
      hidePopupTimerRef.current = null
    }
    setPopupVisible(true)
  }

  const handleMouseLeave = () => {
    if (!isHoveringPopup) {
      hidePopupTimerRef.current = setTimeout(() => {
        setPopupVisible(false)
        setTimeout(() => setPopupRendered(false), 300)
      }, 3000)
    }
  };

  const handlePopupMouseEnter = () => {
    if (hidePopupTimerRef.current) {
      clearTimeout(hidePopupTimerRef.current)
      hidePopupTimerRef.current = null
    }
    setIsHoveringPopup(true)
  };

  const handlePopupMouseLeave = () => {
    setIsHoveringPopup(false)
    hidePopupTimerRef.current = setTimeout(() => {
      setPopupVisible(false)
    }, 3000)
  }

  if (!isVisible) {
    return null
  }

  return (
    <>
      <GlobalButtonWrapper id="quick-exit">
        <QEWrapper>
          {isPopupVisible && (
            <StyledPopUp
              isPopupVisible={isPopupVisible}
              className={isPopupVisible ? "show" : "hide"}
              onMouseEnter={handlePopupMouseEnter}
              onMouseLeave={handlePopupMouseLeave}
            >
              {`If you need to leave our website quickly and safely, use this quick exit button. `}
              <a
                href="https://www.creditunionsa.com.au/help-and-support/financial-abuse"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about financial abuse
              </a>.
            </StyledPopUp>
          )}
          <StyledGlobalButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleQuickExit}
          >
            {`Quick exit`}
          </StyledGlobalButton>
          <StyledCloseButton onClick={handleClose}>
            <Icon icon={faXmark} />
          </StyledCloseButton>
        </QEWrapper>
      </GlobalButtonWrapper>
    </>
  )
}
